<script>
import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";
// import car from "./insert_veiculo.vue";
// import rota from "./insert_rota.vue"

export default {
  components: {
    Multiselect,
    //car,
    //rota
  },
  props: {
    showModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      loader: { get: false },
      titleBody: "Incluir Veiculo",
      tabelas: {
        ttp_nome: null,
      },
      cidades: [
        {
          id: 1,
          nome: "Petrolina",
        },
        {
          id: 2,
          nome: "juazeiro",
        },
      ],
      origens: [
        {
          id: 1,
          nome: "Terceiros",
        },
        {
          id: 2,
          nome: "Particular",
        },
      ],
      carrocerias: [
        {
          id: 1,
          nome: "não se aplica",
        },
        {
          id: 2,
          nome: "Aberta",
        },
        {
          id: 3,
          nome: "Bau/fechada",
        },
        {
          id: 4,
          nome: "granelera",
        },
        {
          id: 5,
          nome: "porta Container",
        },
        {
          id: 6,
          nome: "Sider",
        },
      ],
      tp_rodadados: [
        {
          id: 1,
          nome: "Truck",
        },
        {
          id: 2,
          nome: "Toco",
        },
        {
          id: 3,
          nome: "Cavalo Mecanico",
        },
        {
          id: 4,
          nome: "Van",
        },
        {
          id: 5,
          nome: "Utilitarios",
        },
        {
          id: 6,
          nome: "Outros",
        },
      ],
      veiculo: {
        placa: null,
        cidadade_id: null,
        origem_id: null,
        km_atual: null,
        tara: null,
        capacidade_max: null,
        carroceria_id: null,
        tp_rodado: null,
        tp_pessoa: null,
        nomeRazao: null,
        cpfcnpj: null,
        rntrc: null,
        obs: null,
      },
      sec_cidade: null,
      sec_origem: null,
      sec_tp_carroceria: null,
      sec_tp_rodado: null,
      tp_pessoa: null,
      nome_tp_pessoa: "Razão Social",
      cpfcnpj: "CNPJ",
      motorista: [
        {
          id: 1,
          nome: "motorista1",
        },
      ],
      veiculos: [
        {
          id: 1,
          nome: "veiculos1",
        },
      ],
      rotas: [
        {
          id: 1,
          nome: "rota1",
        },
      ],
      insert_embarque: {
        motorista_id: null,
        veiculo_id: null,
        rota_id: null,
        identificacao: null,
        notas: null,
        data_carregamento: null,
        data_previsao: null,
        obs: null,
      },
      select_motorista: null,
      select_rota: null,
      select_veiculos: null,
      open_modal_car: false,
      open_modal_rota: false,
    };
  },
  validations() {
    return {
      tabelas: {
        ttp_nome: { required },
      },
    };
  },
  mounted() {
    this.setNewTitle();
  },
  methods: {
    modal_car() {
      this.open_modal_car = true;
    },
    modal_rota() {
      this.open_modal_rota = true;
    },
    // back() {
    //   this.open_modal_rota = false;
    //   this.open_modal_car = false;
    // },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPost() {
      this.coverter_float()
      this.$emit("doPost", this.veiculo);
      this.voltar();
    },
    voltar() {
      this.$emit("back");
    },
    seleciona_moto(event) {
      //console.log(event)
      this.insert_embarque.motorista_id = event.id;
    },
    seleciona_rota(event) {
      this.insert_embarque.rota_id = event.id;
      //console.log(event)
    },
    seleciona_veiculo(event) {
      this.insert_embarque.veiculo_id = event.id;
      ///console.log(event)
    },
    customLabel_car({ id, nome }) {
      return `${id} – ${nome}`;
    },
    customLabel_rota({ id, nome }) {
      return `${id} – ${nome}`;
    },
    formatInput_valor() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.veiculo.km_atual.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.veiculo.km_atual = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_valor_cap() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.veiculo.capacidade_max.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.veiculo.capacidade_max = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    coverter_float() {
      //typeof(this.pre_ped.valor)
      if (typeof this.veiculo.km_atual == "string") {
        this.veiculo2 = this.veiculo;
        let stringValor = this.veiculo2.km_atual;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.veiculo.km_atual = valorFlutuante;
      }
      if (typeof this.veiculo.capacidade_max == "string") {
        this.veiculo3 = this.veiculo;
        let stringValor = this.veiculo3.capacidade_max;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.veiculo.capacidade_max = valorFlutuante;
      }
      
    },
    select_cidade(e) {
      this.veiculo.cidadade_id = e.id;
    },
    select_origem(e) {
      this.veiculo.origem_id = e.id;
    },
    select_tp_carroceria(e) {
      this.veiculo.carroceria_id = e.id;
    },
    select_tp_rodado(e) {
      this.veiculo.tp_rodado = e.id;
    },
    set_nome_cpf_cnpj() {
      console.log(this.veiculo.tp_pessoa);
      if (this.veiculo.tp_pessoa == 1) {
        this.nome_tp_pessoa = "Nome";
        this.cpfcnpj = "CPF";
      } else if (this.veiculo.tp_pessoa == 2) {
        this.nome_tp_pessoa = "Razão Social";
        this.cpfcnpj = "CNPJ";
      } else {
        this.this.nome_tp_pessoa = "Razão Social";
        this.cpfcnpj = "CNPJ";
      }
    },
    back() {
      this.$emit("back");
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    
    
  },
};

</script>

<template>
  <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-3">
            <Label>Placa:</Label>
            <input v-model="veiculo.placa" type="text" class="form-control" />
          </div>
          <div class="col-md-3">
            <Label>Cidade:</Label>
            <multiselect
              @select="select_cidade($event)"
              v-model="sec_cidade"
              :options="cidades"
              label="nome"
            >
            </multiselect>
          </div>
          <div class="col-md-3">
            <Label>Origem:</Label>

            <multiselect
              @select="select_origem($event)"
              v-model="sec_origem"
              :options="origens"
              label="nome"
            >
            </multiselect>
          </div>
          <div class="col-md-3">
            <Label>{{ "*Km atual do veiculo" }}:</Label>
            <input
              @input="formatInput_valor()"
              v-model="veiculo.km_atual"
              type="text"
              class="form-control text-left"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-3">
            <Label>Tara:</Label>
            <input v-model="veiculo.tara" type="text" class="form-control" />
          </div>
          <div class="col-md-3">
            <Label>Capacidade de Carga:</Label>
            <input
              @input="formatInput_valor_cap()"
              v-model="veiculo.capacidade_max"
              type="text"
              class="form-control"
            />
          </div>
          <div class="col-md-3">
            <Label>Tipo de Carroceria:</Label>
            <multiselect
              @select="select_tp_carroceria($event)"
              v-model="sec_tp_carroceria"
              :options="carrocerias"
              label="nome"
            >
            </multiselect>
          </div>
          <div class="col-md-3">
            <Label>{{ "Tipo Rodado" }}:</Label>
            <multiselect
              @select="select_tp_rodado($event)"
              v-model="sec_tp_rodado"
              :options="tp_rodadados"
              label="nome"
            >
            </multiselect>
          </div>
        </div>
        <b-card-header header-tag="header" class="p-1 mt-4" role="tab">
          <b-button block v-b-toggle.pedido variant="light"
            >Proprietario/Transportador</b-button
          >
        </b-card-header>
        <b-collapse id="pedido" accordion="pedido-accordion" role="tabpanel">
          <div
            class="row d-flex justify-content-center align-items-center mt-4"
          >
            <div class="col-md-12 text-center">
              <!-- <Label>Proprietario/Transportador:</Label> -->
            </div>
          </div>
          <div
            class="row mt-4 d-flex justify-content-center align-items-center mb-4"
          >
            <div class="col-md-6 text-center">
              <Label class="font"
                ><b-badge
                  data-name="juridica"
                  class="field-status"
                  variant="info"
                  >{{ "Pessoa Fisica" }}</b-badge
                ></Label
              >
              <input
                @change="set_nome_cpf_cnpj()"
                v-model="veiculo.tp_pessoa"
                type="radio"
                value="1"
                class="form-control"
              />
            </div>
            <div class="col-md-6 text-center">
              <Label class="font"
                ><b-badge
                  data-name="juridica"
                  class="field-status"
                  variant="info"
                  >{{ "Pessoa Juridica" }}</b-badge
                ></Label
              >
              <input
                @change="set_nome_cpf_cnpj()"
                v-model="veiculo.tp_pessoa"
                type="radio"
                value="2"
                class="form-control"
              />
            </div>
          </div>

          <div class="row mt-4 mb-4">
            <div class="col-md-4">
              <Label>{{ nome_tp_pessoa }}</Label>
              <input
                v-model="veiculo.nomeRazao"
                type="text"
                class="form-control text-left"
              />
            </div>
            <div class="col-md-4">
              <Label>{{ cpfcnpj }}</Label>
              <input
                v-model="veiculo.cpfcnpj"
                type="text"
                class="form-control text-left"
              />
            </div>
            <div class="col-md-4">
              <Label>RNTRC/ANTT</Label>
              <input
                v-model="veiculo.rntrc"
                type="text"
                class="form-control text-left"
              />
            </div>
          </div>
          <div class="row">
            <Label>Observações: </Label>
            <div class="col-md-12">
              <textarea
                v-model="veiculo.obs"
                name=""
                id=""
                cols="30"
                rows="10"
                class="form-control text-left"
              ></textarea>
            </div>
          </div>
        </b-collapse>
        <div class="row d-flex justify-content-center align-items-center mt-4">
          <div class="col-md-12 text-center">
            <button class="btn btn-secondary" @click="setPost()">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
</template>